export default {
	routes: {
		public: [
			{ path: "/login", name: "Login", exact: true },
			{ path: "/forgot-password", name: "ForgotPassword", exact: true },
			{
				name: "ResetPassword",
				path: "/reset-password/:token",
				exact: true,
			},
		],
		private: [
			{
				path: "/",
				name: "Dashboard",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/admin/my-account",
				name: "My Account",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/admin/notifications",
				name: "My Notifications",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/admin/change-password",
				name: "Change Password",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				path: "/admin/master-data",
				name: "Master Data",
				exact: true,
				config: {
					config: { policies: [["*"], ["*"]] },
				},
			},
			{
				name: "Edit Profile",
				path: "/account-management/edit/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Privacy Policy",
				path: "/content-management/privacy-policy",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "TermsAndConditions",
				path: "/content-management/terms-n-conditons",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "About Us",
				path: "/content-management/about-us",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "FAQ Listing",
				path: "/faq-management",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Add FAQ",
				path: "/faq-management/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Edit FAQ",
				path: "/faq-management/edit/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "User Listing",
				path: "/user-management",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "User Detail",
				path: "/user-management/detail/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Mood Group Listing",
				path: "/mood-group-management",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Add Mood Group",
				path: "/mood-group-management/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Add Mood",
				path: "/mood-group-management/:id/mood/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Edit Mood",
				path: "/mood-group-management/:id/mood/:moodId/edit",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Mood Group Detail",
				path: "/mood-group-management/detail/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Badge Group Listing",
				path: "/badge-group-management",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Add Badge Group",
				path: "/badge-group-management/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Add Badge",
				path: "/badge-group-management/:id/badge/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Edit Badge",
				path: "/badge-group-management/:id/badge/:badgeId/edit",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Badge Group Detail",
				path: "/badge-group-management/detail/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Activity Listing",
				path: "/activity-management",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Activity Detail",
				path: "/activity-management/detail/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Add Activity",
				path: "/activity-management/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Add Sub Activity",
				path: "/activity-management/:id/sub-activity/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Edit SubActivity",
				path:
					"/activity-management/:id/sub-activity/:subActivityId/edit",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Sticker Pack Listing",
				path: "/sticker-pack-management",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Add Sticker Pack",
				path: "/sticker-pack-management/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Sticker Pack Detail",
				path: "/sticker-pack-management/detail/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Add Sticker",
				path: "/sticker-pack-management/:id/sticker/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Edit Sticker",
				path: "/sticker-pack-management/:id/sticker/:stickerId/edit",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Current Mood Listing",
				path: "/current-mood-management",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Current Mood Detail",
				path: "/current-mood-management/detail/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Survey Listing",
				path: "/survey-management",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Dashboard",
				path: "/dashboard",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Survey Statistics",
				path: "/survey-management/statistics",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Survey Detail",
				path: "/survey-management/detail/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Add Current Mood",
				path: "/current-mood-management/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Fortune Cookie Listing",
				path: "/fortune-cookie-management",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Fortune Cookie Detail",
				path: "/fortune-cookie-management/detail/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Add Fortune Cookie",
				path: "/fortune-cookie-management/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Directory Ads Listing",
				path: "/ads-management/directory",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Directory Ads Detail",
				path: "/ads-management/directory/detail/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Directory Add Ads",
				path: "/ads-management/directory/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "SelfCare Ads Listing",
				path: "/ads-management/selfcare",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "SelfCare Ads Detail",
				path: "/ads-management/selfcare/detail/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "SelfCare Add Ads",
				path: "/ads-management/selfcare/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Event Ads Listing",
				path: "/ads-management/event",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Event Ads Detail",
				path: "/ads-management/event/detail/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Event Add Ads",
				path: "/ads-management/event/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Event Listing",
				path: "/event-management/list",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Event Detail",
				path: "/event-management/detail/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Add Event",
				path: "/event-management/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Event Tag",
				path: "/event-management/tag",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Event Tag Detail",
				path: "/event-management/tag/detail/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Event Add Tag",
				path: "/event-management/tag/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},

			{
				name: "Event Category",
				path: "/event-management/category",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Event Category Detail",
				path: "/event-management/category/detail/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Event Add Category",
				path: "/event-management/category/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},

			{
				name: "Event Add Sub Category",
				path: "/event-management/category/:id/sub-category/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Event Edit SubCategory",
				path:
					"/event-management/category/:id/sub-category/:subCategoryId/edit",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "SelfCare Listing",
				path: "/selfcare-management/list",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "SelfCare Detail",
				path: "/selfcare-management/detail/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Add SelfCare",
				path: "/selfcare-management/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "SelfCare Tag",
				path: "/selfcare-management/tag",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "SelfCare Tag Detail",
				path: "/selfcare-management/tag/detail/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "SelfCare Add Tag",
				path: "/selfcare-management/tag/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},

			{
				name: "SelfCare Category",
				path: "/selfcare-management/category",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "SelfCare Category Detail",
				path: "/selfcare-management/category/detail/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "SelfCare Add Category",
				path: "/selfcare-management/category/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},

			{
				name: "SelfCare Add Sub Category",
				path: "/selfcare-management/category/:id/sub-category/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "SelfCare Edit SubCategory",
				path:
					"/selfcare-management/category/:id/sub-category/:subCategoryId/edit",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},

			{
				name: "Splashscreen Listing",
				path: "/splashscreen-management",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Splashscreen Detail",
				path: "/splashscreen-management/detail/:id",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "Add Splashscreen",
				path: "/splashscreen-management/add",
				exact: true,
				config: { policies: [["*"], ["*"]] },
			},
			{
				name: "CMS Accounts",
				path: "/admin/cms-accounts",
				exact: true,
				config: {
					config: { policies: [["*"], ["*"]] },
				},
			},
			{
				name: "Add CMS Account",
				path: "/admin/cms-accounts/create",
				exact: true,
				config: {
					config: { policies: [["*"], ["*"]] },
				},
			},

			{
				name: "Edit CMS Account Details",
				path: "/admin/cms-accounts/edit/:id",
				exact: true,
				config: {
					config: { policies: [["*"], ["*"]] },
				},
			},

			{
				name: "Role Management",
				path: "/admin/role-management",
				exact: true,
				config: {
					config: { policies: [["*"], ["*"]] },
				},
			},

			{
				name: "Create New Role",
				exact: true,
				path: "/admin/role-management/create",
				config: {
					config: { policies: [["*"], ["*"]] },
				},
			},

			{
				name: "Edit Role",
				exact: true,
				path: "/admin/role-management/edit/:id",
				config: {
					config: { policies: [["*"], ["*"]] },
				},
			},

			{
				name: "Push Notifications",
				exact: true,
				path: "/admin/push-notifications",
				config: {
					config: { policies: [["*"], ["*"]] },
				},
			},

			{
				name: "Create Push Notification",
				exact: true,
				path: "/admin/push-notifications/create",
				config: {
					config: { policies: [["*"], ["*"]] },
				},
			},

			{
				name: "Push Notification Details",
				exact: true,
				path: "/admin/push-notifications/edit/:id",
				config: {
					config: { policies: [["*"], ["*"]] },
				},
			},
			{
				name: "Master Audit Trail",
				path: "/admin/master-audit-trail",
				config: {
					config: { policies: [["*"], ["*"]] },
				},
			},
		],
	},
};
